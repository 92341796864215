import React, { PureComponent } from 'react';
import { API } from "aws-amplify";
import { Button, Card, Row, Col } from "react-bootstrap";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import { HuePicker } from 'react-color'


export default class Devices extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedDevice: '',
      devices: {},
      buttonClassNames: {}
    };
    this.timer = null;
    this.s = null;
    props.onSocketConnection((s) => {
      this.s = s;
      s.addEventListener('message', this.onWsMessage);
      s.send(JSON.stringify({'action':'status'}));
    })
  }

  onTimeout = (s) => {
    this.s.send(JSON.stringify({'action':'status'}));
  }

  componentWillUnmount() {
    this.s.removeEventListener('message', this.onWsMessage);
    clearTimeout(this.timer);
  }

  onWsMessage = (e) => {
    var msg = JSON.parse(e.data);
    console.log(msg);
    if (msg.type === 'status') {
      this.setState({devices : msg.devices});
      this.makeButtonClassNames();
      clearTimeout(this.timer);
      this.timer = setTimeout(this.onTimeout, 20000);
    }
  };

  makeButtonClassNames() {
    var cn = {};
    this.setState({buttonClassNames: cn});
  }

  async pushButton(serial, pattern) {
    try {
      await API.post("pixels-api", "/pattern", {
        body: {
          serial: serial,
          pattern: pattern,
          patternParams: ''
        }
      });
    } catch (e) {
      // Error
      console.log(e);
    }
  }

  getButtonClassName(currPattern, thisPattern) {
    if (currPattern === thisPattern) {
      return ("btn btn-success btn-sm")
    } else {
      return ("btn btn-secondary btn-sm")
    }
  }

  async sliderValueChange(serial, pattern, field, value) {
    console.log(serial, pattern, field, value)
    var d = this.state.devices;
    d[serial]['patternParams'][pattern][field] = value;
    console.log(d)
    this.setState({devices: d})
    this.makeButtonClassNames();
  }

  async sliderValueFinalChange(serial, pattern, field, value) {
    console.log('final', serial, pattern, field, value)
    var d = this.state.devices;
    d[serial]['patternParams'][pattern][field] = value;
    this.setState({devices: d})
    // Write value to cloud
    try {
      await API.post("pixels-api", "/pattern", {
        body: {
          serial: serial,
          pattern: pattern,
          patternParams: {[field]: value}
        }
      });
    } catch (e) {
      // Error
      console.log(e);
    }
  }


  colorPickerChange(serial, pattern, color) {
    console.log(serial, pattern, color)
    var d = this.state.devices;
    d[serial]['patternParams'][pattern]['r'] = color.r;
    d[serial]['patternParams'][pattern]['g'] = color.g;
    d[serial]['patternParams'][pattern]['b'] = color.b;
    console.log(d)
    this.setState({devices: d})
    this.makeButtonClassNames();
  }

  async colorPickerChangeComplete(serial, pattern, color) {
    console.log(serial, pattern, color)
    var d = this.state.devices;
    d[serial]['patternParams'][pattern]['r'] = color.r;
    d[serial]['patternParams'][pattern]['g'] = color.g;
    d[serial]['patternParams'][pattern]['b'] = color.b;
    console.log(d)
    this.setState({devices: d})
    this.makeButtonClassNames();
    // Write value to cloud
    try {
      await API.post("pixels-api", "/pattern", {
        body: {
          serial: serial,
          pattern: pattern,
          patternParams: {r: color.r, g: color.g, b:color.b}
        }
      });
    } catch (e) {
      // Error
      console.log(e);
    }
  }


  renderPatternParams(serial) {
    var pattern = this.state.devices[serial].pattern;
    if (pattern === 'rainbow' | pattern === 'colourfade' | pattern === 'singlecolourfade') {
      if (this.state.devices[serial].patternParams[pattern].t > 300) {
        this.sliderValueFinalChange(serial, pattern, 't', 300);
      }
      if (this.state.devices[serial].patternParams[pattern].step > 100) {
        this.sliderValueFinalChange(serial, pattern, 'step', 100);
      }
      return (
        <>
          <Row>
            <Col xs={12}>
              &nbsp;
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <h6>Delay</h6>
            </Col>
            <Col xs={9}>
              <RangeSlider
                value={this.state.devices[serial].patternParams[pattern].t}
                min={0}
                max={300}
                step={10}
                size='sm'
                onChange={e => this.sliderValueChange(serial, pattern, 't', e.target.value)}
                onAfterChange={e => this.sliderValueFinalChange(serial, pattern, 't', e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <h6>Step</h6>
            </Col>
            <Col xs={9}>
              <RangeSlider
                value={this.state.devices[serial].patternParams[pattern].step}
                min={1}
                max={100}
                step={1}
                size='sm'
                onChange={e => this.sliderValueChange(serial, pattern, 'step', e.target.value)}
                onAfterChange={e => this.sliderValueFinalChange(serial, pattern, 'step', e.target.value)}
              />
            </Col>
          </Row>
        </>
      )
    }
    if (pattern === 'rgb' | pattern === 'rg') {
      if (this.state.devices[serial].patternParams[pattern].t > 5000) {
        this.sliderValueFinalChange(serial, pattern, 't', 5000);
      }
      return (
        <>
          <Row>
            <Col xs={12}>
              &nbsp;
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <h6>Delay</h6>
            </Col>
            <Col xs={9}>
              <RangeSlider
                value={this.state.devices[serial].patternParams[pattern].t}
                min={100}
                max={5000}
                step={100}
                size='sm'
                onChange={e => this.sliderValueChange(serial, pattern, 't', e.target.value)}
                onAfterChange={e => this.sliderValueFinalChange(serial, pattern, 't', e.target.value)}
              />
            </Col>
          </Row>
        </>
      )
    }
    if (pattern === 'twinkle') {
      if (this.state.devices[serial].patternParams[pattern].t > 400) {
        this.sliderValueFinalChange(serial, pattern, 't', 400);
      }
      return (
        <>
          <Row>
            <Col xs={12}>
              &nbsp;
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <h6>Delay</h6>
            </Col>
            <Col xs={9}>
              <RangeSlider
                value={this.state.devices[serial].patternParams[pattern].t}
                min={0}
                max={400}
                step={10}
                size='sm'
                onChange={e => this.sliderValueChange(serial, pattern, 't', e.target.value)}
                onAfterChange={e => this.sliderValueFinalChange(serial, pattern, 't', e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <h6>Step</h6>
            </Col>
            <Col xs={9}>
              <RangeSlider
                value={this.state.devices[serial].patternParams[pattern].step}
                min={1}
                max={30}
                step={1}
                size='sm'
                onChange={e => this.sliderValueChange(serial, pattern, 'step', e.target.value)}
                onAfterChange={e => this.sliderValueFinalChange(serial, pattern, 'step', e.target.value)}
              />
            </Col>
          </Row>
        </>
      )
    }
    if (pattern === 'cycle') {
      if (this.state.devices[serial].patternParams[pattern].t > 300) {
        this.sliderValueFinalChange(serial, pattern, 't', 300);
      }
      if (this.state.devices[serial].patternParams[pattern].step > 10) {
        this.sliderValueFinalChange(serial, pattern, 'step', 10);
      }
      return (
        <>
          <Row>
            <Col xs={12}>
              &nbsp;
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <h6>Delay</h6>
            </Col>
            <Col xs={9}>
              <RangeSlider
                value={this.state.devices[serial].patternParams[pattern].t}
                min={0}
                max={300}
                step={10}
                size='sm'
                onChange={e => this.sliderValueChange(serial, pattern, 't', e.target.value)}
                onAfterChange={e => this.sliderValueFinalChange(serial, pattern, 't', e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <h6>Step</h6>
            </Col>
            <Col xs={9}>
              <RangeSlider
                value={this.state.devices[serial].patternParams[pattern].step}
                min={1}
                max={10}
                step={1}
                size='sm'
                onChange={e => this.sliderValueChange(serial, pattern, 'step', e.target.value)}
                onAfterChange={e => this.sliderValueFinalChange(serial, pattern, 'step', e.target.value)}
              />
            </Col>
          </Row>
        </>
      )
    }
    if (pattern === 'colour') {
      return (
        <>
          <Row>
            <Col xs={12}>
              &nbsp;
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <HuePicker
                color={this.state.devices[serial].patternParams[pattern]}
                width={290}
                onChange={e => this.colorPickerChange(serial, pattern, e.rgb)}
                onChangeComplete={e => this.colorPickerChangeComplete(serial, pattern, e.rgb)}
              />
            </Col>
          </Row>
        </>
      )
    }
  }

  renderOneDevicePatterns(serial) {
    var availPatterns = Object.keys(this.state.devices[serial].availPatterns);
    return availPatterns.map((pattern, i) =>
            <Button
              className={this.getButtonClassName(this.state.devices[serial].availPatterns[pattern], this.state.devices[serial].pattern)}
              onClick={this.pushButton.bind(this, serial, this.state.devices[serial].availPatterns[pattern])}
              key={"dev_"+serial+"_pattern_"+i}
            >
              {this.state.devices[serial].availPatterns[pattern]}
            </Button>
    );
  }

  renderDeviceOffline(serial, i) {
    if (this.state.devices[serial].online === false) {
      return (<span key={"dev_st_"+i} className="h6" style={{color:'red'}}>&nbsp;&nbsp;&nbsp;Offline!</span>)
    } else {
      return (<></>)
    }
  }

  render() {
    var sorted_devices = Object.keys(this.state.devices).sort()
    return sorted_devices.map((serial, i) =>
        <div key={'dev_num_'+i}>
          <Card>
            <Card.Body>
              <Card.Title>{this.state.devices[serial].name}{this.renderDeviceOffline(serial, i)}</Card.Title>
              {this.renderOneDevicePatterns(serial)}
              {this.renderPatternParams(serial)}
            </Card.Body>
          </Card>
          &nbsp;
        </div>
    );
  }
}
