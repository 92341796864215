import React, { PureComponent } from "react";
import { FormControl, FormLabel, Row, Col, Card, Modal, Button } from "react-bootstrap";
import { Auth, API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'



export default class CustomPatterns extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showHelp: false,
      showCreate: false,
      modalMode: 'Create',
      custom: {},
      customName: '',
      init: '',
      loop: ''
    };
  }

  async componentDidMount() {
   await Auth.currentSession();
   await this.getPatterns();
  }

  async getPatterns() {
    try {
      const patterns = await API.get("pixels-api", "/custom");
      console.log(patterns);
      this.setState({custom: patterns});
    } catch (e) {
      alert(e);
    }
  }

  handleCloseHelp() {
    this.setState({showHelp: false})
  }

  showHelp() {
    this.setState({showHelp: true})
  }

  handleCloseCreate() {
    this.setState({showCreate: false})
  }

  async handleSaveCreate() {
    console.log(this.state);
    if (this.state.modalMode === 'Create') {
      await API.post("pixels-api",
                     "/custom",
                     {
                       body: {'name': this.state.customName,
                              'init': this.state.init,
                              'loop': this.state.loop}
                     }
                    )
    } else {
      // Edit
      await API.put("pixels-api",
                    "/custom/"+this.state.editId,
                    {
                      body: {'name': this.state.customName,
                             'init': this.state.init,
                             'loop': this.state.loop}
                    }
                   )
    }
    await this.getPatterns();
    this.setState({showCreate: false, customName:'', init:'', loop:'', editId:''})
  }

  showCreate() {
    this.setState({modalMode: 'Create', showCreate: true})
  }

  handleChange(event) {
    let fieldName = event.target.name;
    let fieldVal = event.target.value;
    this.setState({[fieldName]: fieldVal});
  }

  async handleCustomChange(pattern, event) {
    var s = this.state;
    s.custom[pattern].enabled = !s.custom[pattern].enabled;
    this.setState(s);
  }

  async handleCustomEdit(pattern, event) {
    var p = await API.get("pixels-api","/custom/"+pattern)
    console.log(p);
    this.setState({customName: p.name, init: p.init, loop: p.loop, modalMode: 'Edit', editId: pattern, showCreate: true})
  }

  async handleCustomDelete(pattern, event) {
    console.log(pattern)
    await API.del("pixels-api","/custom/"+pattern)
    await this.getPatterns();
  }

  renderCustom() {
    var customKeys = Object.keys(this.state.custom)
    console.log(customKeys)
    return customKeys.map((pattern, i) =>
      <div key={"custom_num_"+i}>
        <Card>
          <Card.Body>
            <Card.Title>{this.state.custom[pattern]}</Card.Title>
            <Row>
              <Col xs={6}>
                <Button block variant="primary" onClick={this.handleCustomEdit.bind(this, pattern)}><FontAwesomeIcon icon={faEdit} className="fa-fw"/> Edit</Button>
              </Col>
              <Col xs={6}>
                <Button block variant="warning" onClick={this.handleCustomDelete.bind(this, pattern)}><FontAwesomeIcon icon={faTrash} className="fa-fw"/> Delete</Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        &nbsp;
      </div>
    );
  }

  render() {
    return (
     <div className="p-3">
      <h3>Pattern Editor</h3>
      {this.renderCustom()}

      <LoaderButton
        block
        type="button"
        className="btn btn-primary"
        onClick={this.showCreate.bind(this)}
      >
        New
      </LoaderButton>

      <Modal show={this.state.showCreate} onHide={this.handleCloseCreate.bind(this)}>
        <Modal.Header closeButton>
          <Modal.Title>{this.state.modalMode} Pattern</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <FormLabel className="col-sm-2 col-form-label">Name</FormLabel>
          <FormControl
            type='text'
            name='customName'
            defaultValue={this.state.customName}
            onChange={this.handleChange.bind(this)}
          />
            <FormLabel className="col-sm-2 col-form-label">Run Once</FormLabel>
          <FormControl
                  as='textarea'
                  name='init'
                  rows="2"
                  style={{fontFamily:"monospace"}}
                  defaultValue={this.state.init}
                  onChange={this.handleChange.bind(this)}
                />
            <FormLabel className="col-sm-2 col-form-label">Loop</FormLabel>
          <FormControl
                  as='textarea'
                  name='loop'
                  rows="5"
                  style={{fontFamily:"monospace"}}
                  defaultValue={this.state.loop}
                  onChange={this.handleChange.bind(this)}
                />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="warning" onClick={this.showHelp.bind(this)}>Help</Button>
          <Button variant="secondary" onClick={this.handleCloseCreate.bind(this)}>Close</Button>
          <Button variant="primary" onClick={this.handleSaveCreate.bind(this)}>Save changes</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={this.state.showHelp} onHide={this.handleCloseHelp.bind(this)}>
        <Modal.Header closeButton>
          <Modal.Title>Help</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Patterns are executed on the pixel controller device, when the pattern is selected from the web interface.</p>
          <p>Each pattern is split into two parts: <i>Run Once</i>, run at the start of the pattern
          execution, and <i>Loop</i>, called repeatedly after that. One or both can be used. To pass data from init to loop, store it in the
          dictionary <code>data</code>.</p>
          <p>Both <i>Run Once</i> and <i>Loop</i> have the
          variables <code>pixelsLength</code> and <code>pixels</code> in their scope, as well as
          the <code>random</code> module. <code>pixelsLength</code> is an
          integer, defining how many pixels are configured on the device. <code>pixels</code> is the instance of
          the <code>NeoPixel</code> class. See
          the <a href="https://docs.micropython.org/en/latest/esp8266/tutorial/neopixel.html">help</a> text for how to
          interact with it. Ensure that <code>pixels.write()</code> is called at least once loop iteration.</p>
          <p>The <i>Loop</i> code also has the function <code>sleep</code> (from the <code>time</code> module) in its scope.</p>
          <p>Each iteration of the loop should be kept as short as possible, to allow for responsive ending of the
          pattern.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCloseHelp.bind(this)}>Close</Button>
        </Modal.Footer>
      </Modal>

    </div>
    );
  }
}



//      <form>
//        <Card>
//          <Card.Body>
//            <Card.Title>Custom Patterns</Card.Title>
//            <div className="form-group">
//
//            </div>
//            <Row>
//              <Col>
//              </Col>
//              <Col>
//
//              </Col>
//            </Row>
//          </Card.Body>
//        </Card>











//export default function Settings(props) {
//  // State settings for modal
//  const [userDetails, setUserDetails] = useState({"email":"","serial":"","firmwareVersion":"","lastTime":0});
//  const [postUrl, setPostUrl] = useState("");
//  const [isLoading, setIsLoading] = useState(false);
//
//  useEffect(() => {
//    updatePage();
//    // eslint-disable-next-line react-hooks/exhaustive-deps
//  }, []);
//
//  async function updatePage() {
//    var retValue = await API.get("pixels-api", "/settings");
//    console.log(retValue);
//    setPostUrl(retValue.url || '');
//    setUserDetails({"email":retValue.email,"serial":retValue.serial,"firmwareVersion":retValue.firmwareVersion,"lastTime":retValue.timestamp})
//  }
//
//  function changePassword() {
//    props.history.push("/settings/password");
//  }
//
//  async function globalSignout() {
//    await Auth.signOut({ global: true });
//    props.userHasAuthenticated(false);
//    props.history.push("/login");
//  }
//
//  async function saveSettings() {
//    setIsLoading(true);
//    try {
//      await API.post("garage-api", "/settings", {
//        body: {
//          url: postUrl
//        }
//      });
//    } catch (e) {
//      // Error
//    }
//    setIsLoading(false);
//  }
//
//  async function testPostUrl() {
//    setIsLoading(true);
//    try {
//      await API.post("garage-api", "/settings", {
//        body: {
//          testUrl: postUrl
//        }
//      });
//    } catch (e) {
//      // Error
//    }
//    setIsLoading(false);
//  }
//
//  return (
//    <>
//       <div className="Settings">
//        <h3>Settings</h3>
//
//         <div className="p-3">
//          <Card>
//            <Card.Body>
//              <Card.Title>Account Information</Card.Title>
//              <div className="form-group">
//                <label htmlFor="inputUserEmail">User email</label>
//                <input type="text" className="form-control" id="inputUserEmail" disabled value={userDetails.email} />
//              </div>
//              <div className="form-group">
//                <label htmlFor="inputDeviceSerial">Device Serial</label>
//                <input type="text" className="form-control" id="inputDeviceSerial" disabled value={userDetails.serial} />
//              </div>
//              <div className="form-group">
//                <label htmlFor="inputDeviceFirmware">Firmware Version</label>
//                <input type="text" className="form-control" id="inputDeviceFirmware" disabled value={userDetails.firmwareVersion} />
//              </div>
//              <div className="form-group">
//                <label htmlFor="inputLastContact">Last Contact Time</label>
//                <input type="text" className="form-control" id="inputLastContact" disabled value={userDetails.lastTime} />
//              </div>
//              <LoaderButton
//                block
//                type="button"
//                className="btn btn-primary"
//                isLoading={isLoading}
//                onClick={changePassword}
//              >
//                Change Password
//              </LoaderButton>
//              <LoaderButton
//                block
//                type="button"
//                className="btn btn-primary"
//                isLoading={isLoading}
//                onClick={globalSignout}
//              >
//                Sign out of all devices
//              </LoaderButton>
//            </Card.Body>
//          </Card>
//        </div>
//
//         <div className="p-3">
//          <Card>
//            <Card.Body>
//              <Card.Title>Alert Settings</Card.Title>
//              <form>
//                <div className="form-group">
//                  <label htmlFor="inputPostUrl">POST URL</label>
//                  <input type="text" className="form-control" id="inputPostUrl" placeholder="Enter URL" value={postUrl} onChange={e => setPostUrl(e.target.value)} />
//                  <small id="inputPostUrlHelp" className="form-text text-muted">Specify a URL that we'll update any time the status changes.</small>
//                </div>
//                <LoaderButton
//                  block
//                  type="button"
//                  className="btn btn-primary"
//                  isLoading={isLoading}
//                  onClick={testPostUrl}
//                >
//                  Test URL
//                </LoaderButton>
//                <LoaderButton
//                  block
//                  type="button"
//                  className="btn btn-primary"
//                  isLoading={isLoading}
//                  onClick={saveSettings}
//                >
//                  Save settings
//                </LoaderButton>
//              </form>
//            </Card.Body>
//          </Card>
//
//        </div>
//      </div>
//    </>
//  );
//}