import React, { PureComponent } from "react";
import "./Home.css";
//import DoorButton from "../components/DoorButton";
//import StatusIndicator from "../components/DoorStatusIndicator";
import Devices from "../components/Devices";
import { Auth } from "aws-amplify";
import config from "../config";

// Main function
export default class MainPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ws: null
    };
    this.wsOnOpen = []; // from child components
  }

  getSocket() {
    return this.state.ws;
  }

  componentDidMount() {
    this.connectWebsocket();
  }
  timeout = 250; // Initial timeout duration as a class variable

  onSocketConnection(e) {
    // Called by child components to add things to do on connection of websocket
    this.wsOnOpen.push(e);
  }

  async connectWebsocket() {
    var x = await Auth.currentSession();
    var ws = new WebSocket(config.wsApiGateway.URL + "?token=" + x.accessToken.jwtToken);
    let that = this; // cache the this
    var connectInterval;

    ws.onopen = () => {
      console.log("connected websocket main component");
      this.setState({ ws: ws });
      that.timeout = 250; // reset timer to 250 on open of websocket connection
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
      // Now run all the functions that child components added
      for (const f of this.wsOnOpen) {
        f(ws);
      }
    };

    ws.onclose = e => {
      console.log(
        `Socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (that.timeout + that.timeout) / 1000
        )} second.`,
        e.reason
      );
      that.timeout = that.timeout + that.timeout; //increment retry interval
      connectInterval = setTimeout(this.checkWebsocket, Math.min(10000, that.timeout)); //call check function after timeout
    };

    // websocket onerror event listener
    ws.onerror = err => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );
      ws.close();
    };
  };

  checkWebsocket = () => {
    const { ws } = this.state;
    if (!ws || ws.readyState === WebSocket.CLOSED) {
      // check if websocket instance is closed, if so call `connectWebsocket` function.
      this.connectWebsocket();
    }
  };

  render() {
    return (
      <div className="Main">
        <div className="container">
          <div className="row">
            <div className="col-md align-self-center text-center">
              <img src="banner.png" className="img-fluid" alt="lights"/>
            </div>
          </div>
          <div className="row">
            &nbsp;
          </div>
          <Devices onSocketConnection={this.onSocketConnection.bind(this)} />
        </div>
      </div>
    );
  }
}

//
//                <img src="banner.png"/>
            //<LogTable onSocketConnection={this.onSocketConnection.bind(this)} />
//              <StatusIndicator onSocketConnection={this.onSocketConnection.bind(this)} />
//              <DoorButton />

