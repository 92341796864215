import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Home from "./containers/Home";
import Login from "./containers/Login";
import MainPage from "./containers/MainPage";
import NotFound from "./containers/NotFound";
import CustomPatterns from "./containers/CustomPatterns";
import EnabledPatterns from "./containers/EnabledPatterns";
import Settings from "./containers/Settings";
import ChangePassword from "./containers/ChangePassword";
import ResetPassword from "./containers/ResetPassword";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute path="/" exact component={Home} appProps={appProps} />
      <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
      <UnauthenticatedRoute exact path="/login/reset">
        <ResetPassword />
      </UnauthenticatedRoute>
      <AuthenticatedRoute path="/main" exact component={MainPage} appProps={appProps} />
      <AuthenticatedRoute path="/patterns" exact component={EnabledPatterns} appProps={appProps} />
      <AuthenticatedRoute path="/custom" exact component={CustomPatterns} appProps={appProps} />
      <AuthenticatedRoute path="/settings" exact component={Settings} appProps={appProps} />
      <AuthenticatedRoute exact path="/settings/password">
        <ChangePassword />
      </AuthenticatedRoute>
      { /* Finally, catch all unmatched routes */ }
      <Route component={NotFound} />
    </Switch>
  );
}