import React, { PureComponent } from "react";
import { Card, FormControl } from "react-bootstrap";
import { Auth, API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";


export default class Settings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      latestVersion: '',
      email: '',
      devices: {},
      firmwareUpdates: {}
    };
  }

  async componentDidMount() {
   await Auth.currentSession();
    try {
      const settings = await API.get("pixels-api", "/settings");
      console.log(settings);
      var fwUpdates = {}
      for (var serial in settings.devices) {
        fwUpdates[serial] = {'loading' : false, 'disabled': false}
      }
      this.setState({email: settings.email,
                     devices: settings.devices,
                     latestVersion: settings.latestVersion,
                     firmwareUpdates: fwUpdates});
    } catch (e) {
      alert(e);
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    this.setState({isLoading: true});
    try {
      await this.saveSettings();
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({isLoading: false});
    }
  }

  saveSettings() {
    var bodyInfo = {}
    for (var serial in this.state.devices) {
      bodyInfo[serial] = {'name'   : this.state.devices[serial].name,
                          'length' : this.state.devices[serial].length}
    }
    return API.post("pixels-api",
                    "/settings",
                    {
                      body: {'deviceSettings':bodyInfo}
                    }
                    )

  }

  changePassword() {
    this.props.history.push("/settings/password");
  }

  async globalSignout() {
    await Auth.signOut({ global: true });
    this.props.userHasAuthenticated(false);
    this.props.history.push("/login");
  }

  updateName = serial => (event) => {
    let newName = event.target.value;
    var s = this.state;
    s.devices[serial].name = newName;
    this.setState(s);
    console.log(s);
  }

  updateLength = serial => (event) => {
    let newLength = event.target.value;
    var s = this.state;
    s.devices[serial].length = newLength;
    this.setState(s);
    console.log(s);
  }

  async updateFirmware(serial) {
    var fwUpdates = this.state.firmwareUpdates;
    fwUpdates[serial].loading = true
    this.setState({firmwareUpdates: fwUpdates})
    await API.post("pixels-api",
                    "/reboot",
                    {
                      body: {serial: serial}
                    }
                    )
    fwUpdates = this.state.firmwareUpdates;
    fwUpdates[serial].loading = false
    fwUpdates[serial].disabled = true
    this.setState({firmwareUpdates: fwUpdates})
  }

  renderFirmwareUpdate(serial) {
    console.log(this.state.devices[serial].version)
    console.log(this.state.latestVersion)
    console.log(this.state.devices[serial].version < this.state.latestVersion)
    if (this.state.devices[serial].version < this.state.latestVersion) {
      return (
            <LoaderButton
              block
              type="button"
              className="btn btn-primary"
              isLoading={this.state.firmwareUpdates[serial].loading}
              hidden={this.state.firmwareUpdates[serial].disabled}
              onClick={this.updateFirmware.bind(this, serial)}
            >
              Update firmware
            </LoaderButton>)
    } else {
      return (<></>)
    }
  }

  renderDevices() {
    let devHtml = [];
    for (var serial in this.state.devices) {
      devHtml.push(
        <div key={'devNumKey'+serial}>
          <Card>
            <Card.Body>
              <Card.Title>{serial}</Card.Title>
              <div className="form-group">
                <label htmlFor={'name-'+serial}>Device Name</label>
                <FormControl type='text' name={'name-'+serial} defaultValue={this.state.devices[serial].name} onChange={this.updateName(serial)} />
              </div>
              <div className="form-group">
                <label htmlFor={'length-'+serial}>Number of pixels</label>
                <FormControl type='number' name={'length-'+serial} defaultValue={this.state.devices[serial].length} onChange={this.updateLength(serial)} />
              </div>
              {this.renderFirmwareUpdate(serial)}
            </Card.Body>
          </Card>
          &nbsp;
        </div>
      );
    }
    return devHtml;
  }

  render() {
    return (
     <div className="p-3">
      <h3>Settings</h3>
      <form onSubmit={this.handleSubmit.bind(this)}>
        <Card>
          <Card.Body>
            <Card.Title>Account Information</Card.Title>
            <div className="form-group">
              <label htmlFor="inputUserEmail">User email</label>
              <input type="text" className="form-control" id="inputUserEmail" disabled value={this.state.email} />
            </div>
            <LoaderButton
              block
              type="button"
              className="btn btn-primary"
              isLoading={this.state.isLoading}
              onClick={this.changePassword.bind(this)}
            >
              Change Password
            </LoaderButton>
            <LoaderButton
              block
              type="button"
              className="btn btn-primary"
              isLoading={this.state.isLoading}
              onClick={this.globalSignout.bind(this)}
            >
              Sign out of all devices
            </LoaderButton>
          </Card.Body>
        </Card>

        &nbsp;

        {this.renderDevices()}

        <LoaderButton
          block
          type="button"
          className="btn btn-primary"
          isLoading={this.state.isLoading}
          onClick={this.handleSubmit.bind(this)}
        >
          Save settings
        </LoaderButton>
      </form>

    </div>
    );
  }
}














//export default function Settings(props) {
//  // State settings for modal
//  const [userDetails, setUserDetails] = useState({"email":"","serial":"","firmwareVersion":"","lastTime":0});
//  const [postUrl, setPostUrl] = useState("");
//  const [isLoading, setIsLoading] = useState(false);
//
//  useEffect(() => {
//    updatePage();
//    // eslint-disable-next-line react-hooks/exhaustive-deps
//  }, []);
//
//  async function updatePage() {
//    var retValue = await API.get("pixels-api", "/settings");
//    console.log(retValue);
//    setPostUrl(retValue.url || '');
//    setUserDetails({"email":retValue.email,"serial":retValue.serial,"firmwareVersion":retValue.firmwareVersion,"lastTime":retValue.timestamp})
//  }
//
//  function changePassword() {
//    props.history.push("/settings/password");
//  }
//
//  async function globalSignout() {
//    await Auth.signOut({ global: true });
//    props.userHasAuthenticated(false);
//    props.history.push("/login");
//  }
//
//  async function saveSettings() {
//    setIsLoading(true);
//    try {
//      await API.post("garage-api", "/settings", {
//        body: {
//          url: postUrl
//        }
//      });
//    } catch (e) {
//      // Error
//    }
//    setIsLoading(false);
//  }
//
//  async function testPostUrl() {
//    setIsLoading(true);
//    try {
//      await API.post("garage-api", "/settings", {
//        body: {
//          testUrl: postUrl
//        }
//      });
//    } catch (e) {
//      // Error
//    }
//    setIsLoading(false);
//  }
//
//  return (
//    <>
//       <div className="Settings">
//        <h3>Settings</h3>
//
//         <div className="p-3">
//          <Card>
//            <Card.Body>
//              <Card.Title>Account Information</Card.Title>
//              <div className="form-group">
//                <label htmlFor="inputUserEmail">User email</label>
//                <input type="text" className="form-control" id="inputUserEmail" disabled value={userDetails.email} />
//              </div>
//              <div className="form-group">
//                <label htmlFor="inputDeviceSerial">Device Serial</label>
//                <input type="text" className="form-control" id="inputDeviceSerial" disabled value={userDetails.serial} />
//              </div>
//              <div className="form-group">
//                <label htmlFor="inputDeviceFirmware">Firmware Version</label>
//                <input type="text" className="form-control" id="inputDeviceFirmware" disabled value={userDetails.firmwareVersion} />
//              </div>
//              <div className="form-group">
//                <label htmlFor="inputLastContact">Last Contact Time</label>
//                <input type="text" className="form-control" id="inputLastContact" disabled value={userDetails.lastTime} />
//              </div>
//              <LoaderButton
//                block
//                type="button"
//                className="btn btn-primary"
//                isLoading={isLoading}
//                onClick={changePassword}
//              >
//                Change Password
//              </LoaderButton>
//              <LoaderButton
//                block
//                type="button"
//                className="btn btn-primary"
//                isLoading={isLoading}
//                onClick={globalSignout}
//              >
//                Sign out of all devices
//              </LoaderButton>
//            </Card.Body>
//          </Card>
//        </div>
//
//         <div className="p-3">
//          <Card>
//            <Card.Body>
//              <Card.Title>Alert Settings</Card.Title>
//              <form>
//                <div className="form-group">
//                  <label htmlFor="inputPostUrl">POST URL</label>
//                  <input type="text" className="form-control" id="inputPostUrl" placeholder="Enter URL" value={postUrl} onChange={e => setPostUrl(e.target.value)} />
//                  <small id="inputPostUrlHelp" className="form-text text-muted">Specify a URL that we'll update any time the status changes.</small>
//                </div>
//                <LoaderButton
//                  block
//                  type="button"
//                  className="btn btn-primary"
//                  isLoading={isLoading}
//                  onClick={testPostUrl}
//                >
//                  Test URL
//                </LoaderButton>
//                <LoaderButton
//                  block
//                  type="button"
//                  className="btn btn-primary"
//                  isLoading={isLoading}
//                  onClick={saveSettings}
//                >
//                  Save settings
//                </LoaderButton>
//              </form>
//            </Card.Body>
//          </Card>
//
//        </div>
//      </div>
//    </>
//  );
//}