import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
import Routes from "./Routes";
import "./App.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faEdit, faPlug, faCogs, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'


function App(props) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch(e) {
      console.log(e);
    }
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    props.history.push("/login");
  }

  return (
    !isAuthenticating &&
    <div className="App container">
      {isAuthenticated ? (
          <Navbar className="fixed-bottom" expand="lg" variant="dark" bg="dark">
            <LinkContainer to="/main">
              <Nav.Link><FontAwesomeIcon icon={faHome} className="fa-fw"/></Nav.Link>
            </LinkContainer>
            <LinkContainer to="/patterns">
              <Nav.Link><FontAwesomeIcon icon={faPlug} className="fa-fw"/></Nav.Link>
            </LinkContainer>
            <LinkContainer to="/custom">
              <Nav.Link><FontAwesomeIcon icon={faEdit} className="fa-fw"/></Nav.Link>
            </LinkContainer>
            <LinkContainer to="/settings">
              <Nav.Link><FontAwesomeIcon icon={faCogs} className="fa-fw"/></Nav.Link>
            </LinkContainer>
            <Nav.Link onClick={handleLogout}><FontAwesomeIcon icon={faSignOutAlt} className="fa-fw"/></Nav.Link>
          </Navbar>
      ): ( <> </> )}

      <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
    </div>
  );
}

export default withRouter(App);