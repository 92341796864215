const config = {
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://pixels-api.penguinseatcheese.co.uk"
  },
  wsApiGateway: {
    URL: "wss://pixels-ws.penguinseatcheese.co.uk/"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_eukPF2ls6",
    APP_CLIENT_ID: "1f87b0chf270mle2o5q6q38ks9",
    IDENTITY_POOL_ID: "eu-west-1:3acf4243-410d-494d-86be-5f6aec3bc352"
  }
};

export default config;