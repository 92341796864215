import React, { PureComponent } from "react";
import { Form, Card, Col } from "react-bootstrap";
import { Auth, API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";


export default class EnabledPatterns extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      devices: {}
    };
  }

  async componentDidMount() {
   await Auth.currentSession();
   await this.getPatterns();
  }

  async getPatterns() {
    try {
      const patterns = await API.get("pixels-api", "/patterns");
      console.log(patterns);
      this.setState({devices: patterns.devices,
                     serials: patterns.serials,
                     patterns: patterns.patterns});
    } catch (e) {
      alert(e);
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    this.setState({isLoading: true});
    try {
      await this.saveSettings();
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({isLoading: false});
    }
  }

  saveSettings() {
    console.log(this.state);
    return API.post("pixels-api",
                    "/patterns",
                    {
                      body: this.state.patterns
                    }
                   )
  }

  handleBuiltinChange(serial, pattern, event) {
  console.log(serial, pattern)
  console.log(this.state.patterns[serial][pattern])
    var s = this.state;
    s.patterns[serial][pattern] = !s.patterns[serial][pattern];
    this.setState(s);
  }

  renderOneDevice(serial) {
    var patterns = Object.keys(this.state.patterns[serial])
    return patterns.map((pattern, i) =>
            <Col xs={6} key={"pattern_"+serial+"_"+i}>
              <Form.Check type="checkbox" label={pattern} defaultChecked={this.state.patterns[serial][pattern]} onChange={this.handleBuiltinChange.bind(this, serial, pattern)} />
            </Col>
    );
  }


  renderDevices() {

    var sorted_devices = Object.keys(this.state.devices).sort()
    return sorted_devices.map((serial, i) =>
        <div key={'dev_num_'+i}>
          <Card>
            <Card.Body>
              <Card.Title>{this.state.devices[serial]}</Card.Title>
              {this.renderOneDevice(serial)}
            </Card.Body>
          </Card>
          &nbsp;
        </div>
    );
  }


  render() {
    return (
     <div className="p-3">
      <h3>Enabled Patterns</h3>
      <form onSubmit={this.handleSubmit.bind(this)}>
        {this.renderDevices()}

        <LoaderButton
          block
          type="button"
          className="btn btn-primary"
          isLoading={this.state.isLoading}
          onClick={this.handleSubmit.bind(this)}
        >
          Save settings
        </LoaderButton>

      </form>

    </div>
    );
  }
}

